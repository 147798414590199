.svg-circle__spin {
  animation: spin-around 3.2s linear infinite;
}

@keyframes spin-around {
  0% {
    stroke-dashoffset: 129;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -129;
  }
}
